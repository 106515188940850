<template>
    <div class="maxL">

        <div class="right">
            <div class="right_top">
                <el-row class="dell">
                    <el-button type="info" @click="addbutton">添加</el-button>
                    <!-- <el-button :disabled="bianji" type="info">编辑</el-button> -->
                    <el-button :disabled="xiazai" type="danger" @click="deletebuttin">删除</el-button>
                    <el-button type="warning" @click="reset">重置</el-button>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="站点名称">
                            <el-input v-model="formInline.user" placeholder="输入站点名称搜索"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <el-dialog :title="designation" :visible.sync="dialogFormVisible">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="站点名称" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="所属项目" prop="item">
                        <el-select v-model="ruleForm.item" placeholder="请选择所属项目" style="width: 100%;">
                            <el-option v-for="item in suoshu" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地区" prop="region">
                        <el-select v-model="ruleForm.region" placeholder="请选择区域" style="width: 100%;">
                            <el-option v-for="item in area" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="网站" prop="url">
                        <el-input v-model="ruleForm.url"></el-input>
                    </el-form-item>
                    <el-form-item label="负责小组" prop="group">
                        <el-select v-model="ruleForm.group" multiple placeholder="请选择负责小组" style="width: 100%;">
                            <el-option v-for="item in charge" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="快捷回复标签" >
                        <el-select v-model="ruleForm.label" multiple placeholder="请选择回复标签" style="width: 100%;">
                            <el-option v-for="item in reply" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="信息海来源id" prop="source">
                        <el-input v-model="ruleForm.source"></el-input>
                    </el-form-item>
                    <el-form-item label="信息海公司id" prop="company">
                        <el-input v-model="ruleForm.company"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 表单数据 -->
            <div class="biaodan">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="50" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="itemid" label="项目ID" width="70" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="name" label="站点名称" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="website" label="地址" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button size="mini" type="info" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="mini" type="danger" @click="remove(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="block">
                <el-pagination background layout="total, prev, pager, next, sizes" :total="sumye"
                    :page-sizes="[5, 10, 20, 50, 100]" :page-size="Barpage" @size-change="dangqiang" @current-change="yema">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import { getSitelist, addSitelist, editSitelist, deleteSitelist, getProject, getRegion, getGroup, getReply } from "@/api/jiekou"



export default {
    name: 'XianmuFkmd',
    props: ["tochild"],
    data() {
        return {
            xiazai: true,  //控制删除是否可以触发
            bianji: true,//控制编辑是否可以触发
            dialogFormVisible: false,   //添加弹窗
            form: {
                name: '',
            },
            designation: "添加",
            // 搜索
            formInline: {
                user: ''
            },
            // 弹窗数据
            ruleForm: {
                name: '',
                item: '',
                region: '',
                url: '',
                group: [],
                label: [],
                source: '',
                company: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' }
                ],
                item: [
                    { required: true, message: '请选择所属项目', trigger: 'change' }
                ],
                region: [
                    { required: true, message: '请选择地区', trigger: 'change' }
                ],
                url: [
                    { required: true, message: '请输入网址', trigger: 'blur' }
                ],
                group: [
                    { required: true, message: '请选择负责小组', trigger: 'change' }
                ],
                label: [
                    { required: true, message: '请选择快捷回复标签', trigger: 'change' }
                ],
                source: [
                    { required: true, message: '请输入信息海来源id', trigger: 'blur' }
                ],
                company: [
                    { required: true, message: '请输入信息海公司id', trigger: 'blur' }
                ],
            },

            suoshu: [], // 所属项目
            area: [],  // 地区
            charge: [], // 负责小组
            reply: [],  // 快捷回复标签
            //用户表单
            tableData: [],
            multipleSelection: [], //表格复选框选中的数据
            currentPage4: 4,
            sumye: 0,  //总页数
            Barpage: 5,   //条页  选中的页码条数
            pagination: {},  //存放筛选后的参数  用于页码
            xmId: "",  //存放项目id
        };
    },

    mounted() {
        this.getlie()
        this.dangqiang()
        this.xiangmu()
        this.diqu()
        this.xiaozu()
        this.huifu()
    },
    methods: {
        // 添加弹窗中所属项目列表
        async xiangmu() {
            let res = await getProject()
            // console.log(res.data.data.data)
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.value = val.id
                arr.label = val.project_name
                this.suoshu.push(arr)
            })
        },
        // 添加弹窗中地区列表
        async diqu() {
            let res = await getRegion()
            // console.log(res.data.data.data)
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.value = val.id
                arr.label = val.region_name
                this.area.push(arr)
            })
        },
        // 添加弹窗中负责小组
        async xiaozu() {
            let res = await getGroup()
            // console.log(res.data.data.data)
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.value = val.id
                arr.label = val.group_name
                this.charge.push(arr)
            })
        },
        // 添加弹窗中快捷回复标签
        async huifu() {
            let res = await getReply()
            // console.log(res.data.data)
            res.data.data.forEach(val => {
                let arr = {}
                arr.value = val.id
                arr.label = val.content
                this.reply.push(arr)
            })
        },
        // 点击添加按钮
        addbutton() {
            this.dialogFormVisible = true
            this.designation = "添加"
        },
        // 点击确定添加列表
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.designation == "添加") {
                        let res = await addSitelist(
                            {
                                site_name: this.ruleForm.name,
                                project_id: this.ruleForm.item,
                                region_id: this.ruleForm.region,
                                url: this.ruleForm.url,
                                group: this.ruleForm.group.toString(),
                                tag_id: this.ruleForm.label.toString(),
                                source_id: this.ruleForm.source,
                                company_id: this.ruleForm.company,
                                current: 1,
                                retention: 0,
                            }
                        )
                        alert(res.data.msg)
                        this.dialogFormVisible = false
                        this.getlie()
                    } else {
                        let res = await editSitelist({
                            id: this.ruleForm.id,
                            site_name: this.ruleForm.name,
                            project_id: this.ruleForm.item,
                            region_id: this.ruleForm.region,
                            url: this.ruleForm.url,
                            group: this.ruleForm.group.toString(),
                            tag_id: this.ruleForm.label.toString(),
                            source_id: this.ruleForm.source,
                            company_id: this.ruleForm.company,
                            current: 1,
                            retention: 0,
                        })
                        alert(res.data.msg)
                        this.dialogFormVisible = false
                        this.getlie()
                    }
                    this.ruleForm = {
                        name: '',
                        item: '',
                        region: '',
                        url: '',
                        group: [],
                        label: [],
                        source: '',
                        company: '',
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        //   重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 编辑
        handleEdit(index, value) {
            // console.log(value)
            this.designation = "编辑"
            this.dialogFormVisible = true
            let shortcut=""
            if (value.tag_id) {
              shortcut=  value.tag_id.split(",").map(Number)
            }else{
                shortcut=""
            }
            this.ruleForm = {
                name: value.name,
                item: value.itemid,
                region: value.region_id,
                url: value.website,
                group: value.group.split(",").map(Number),
                label: shortcut,
                source: value.source_id,
                company: value.company_id,
                id: value.id
            }
        },
        // 点击单个删除
        async remove(index, value) {
            // console.log(value.id)
            let res = await deleteSitelist({ id: value.id })
            alert(res.data.msg)
            this.getlie()
        },
        // 选中多个进行删除
        async deletebuttin() {
            let Id = []
            this.multipleSelection.forEach(val => {

                Id.push(val.id)
            })
            // console.log(Id)
            let res = await deleteSitelist({ id: Id.toString() })
            alert(res.data.msg)
            this.getlie()
        },
        // 重置按钮
        reset(){
            this.formInline.user=""
            this.xmId=""
            this.getlie()
            this.$message({
          message: '重置成功',
          type: 'success'
        });
        },
        //表单选中
        handleSelectionChange(val) {
            this.multipleSelection = val;
            val.length == 0 ? this.xiazai = true : this.xiazai = false
            if (val.length == 1) {
                this.bianji = false
            } else {
                this.bianji = true
            }
        },
        // 点击搜索按钮
        async onSubmit() {
            let posmis = {}
            if (this.xmId) {
                posmis.project_id = this.xmId
                posmis.site_name = this.formInline.user
            } else {
                posmis.site_name = this.formInline.user
            }
            // console.log(posmis)
            let res = await getSitelist(posmis)
            this.pagination = posmis
            this.sumye = res.data.data.count
            this.tableData= []
            // console.log(res.data.data.data)
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.id = val.id
                arr.itemid = val.project_id
                arr.name = val.site_name
                arr.website = val.url
                arr.region_id = val.region_id
                arr.group = val.group
                arr.tag_id = val.tag_id
                arr.source_id = val.source_id
                arr.company_id = val.company_id
                this.tableData.push(arr)
            })
        },
        // 访客名单列表
        async getlie() {
            let _this = this
            if (this.xmId) {

                let res = await getSitelist({ project_id: this.xmId })

                _this.tableData = []
                this.pagination = { project_id: this.xmId }
                this.sumye = res.data.data.count
                // console.log(res.data.data)
                _this.tableData.length = []
                res.data.data.data.forEach(val => {
                    let arr = {}
                    arr.id = val.id
                    arr.itemid = val.project_id
                    arr.name = val.site_name
                    arr.website = val.url
                    arr.region_id = val.region_id
                    arr.group = val.group
                    arr.tag_id = val.tag_id
                    arr.source_id = val.source_id
                    arr.company_id = val.company_id
                    _this.tableData.push(arr)
                })
            } else {
                let res = await getSitelist()
                this.sumye = res.data.data.count
                // console.log(res.data.data)
                _this.tableData.length = []
                res.data.data.data.forEach(val => {
                    let arr = {}
                    arr.id = val.id
                    arr.itemid = val.project_id
                    arr.name = val.site_name
                    arr.website = val.url
                    arr.region_id = val.region_id
                    arr.group = val.group
                    arr.tag_id = val.tag_id
                    arr.source_id = val.source_id
                    arr.company_id = val.company_id
                    _this.tableData.push(arr)
                })
            }
        },

        // 页码  每页条数
        async dangqiang(value) {
            let _this = this
            this.Barpage = value
            _this.pagination.limit = value
            let res = await getSitelist(_this.pagination)
            // console.log(res.data.data.data)
            _this.tableData.length = []
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.id = val.id
                arr.itemid = val.project_id
                arr.name = val.site_name
                arr.website = val.url
                arr.region_id = val.region_id
                arr.group = val.group
                arr.tag_id = val.tag_id
                arr.source_id = val.source_id
                arr.company_id = val.company_id
                _this.tableData.push(arr)

            })

        },
        async yema(value) {
            let _this = this
            _this.pagination.limit = this.Barpage
            _this.pagination.page = value
            let res = await getSitelist(_this.pagination)
            // console.log(res.data.data.data)
            _this.tableData.length = []
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.id = val.id
                arr.itemid = val.project_id
                arr.name = val.site_name
                arr.website = val.url
                arr.region_id = val.region_id
                arr.group = val.group
                arr.tag_id = val.tag_id
                arr.source_id = val.source_id
                arr.company_id = val.company_id
                _this.tableData.push(arr)

            })

        },


        //将时间戳转换成日期格式
        timestampToTime(timestamp) {
            // 时间戳为10位需*1000，时间戳为13位不需乘1000
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + "-";
            var M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
        },
        // 中国标准时间转换成日期格式
        timeriqi(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            return time = y + '-' + m + '-' + d;
        }
    },
    watch: {
        tochild: {
            handler: async function (val) {
                // console.log('监听到了' + val);
                this.xmId = val
                let _this = this
                _this.tableData = []
                let res = await getSitelist({ project_id: val })
                if (res.data.code==-1) {
                alert("你的用户失效，请重新登录")
                this.$router.replace("/login");
                
            } else{
                this.pagination = { project_id: val }
                this.sumye = res.data.data.count
                // console.log(res.data.data)
                _this.tableData.length = []
                res.data.data.data.forEach(val => {
                    let arr = {}
                    arr.id = val.id
                    arr.itemid = val.project_id
                    arr.name = val.site_name
                    arr.website = val.url
                    arr.region_id = val.region_id
                    arr.group = val.group
                    arr.tag_id = val.tag_id
                    arr.source_id = val.source_id
                    arr.company_id = val.company_id
                    _this.tableData.push(arr)
                })
            }
                
            },
            deep: true,
        }
    },
};
</script>

<style lang="less" scoped>
.maxL {
    display: flex;

}


.right {
    width: 100%;
    padding: 10px 10px 0 15px;
}

.right .right_top {
    width: 100%;
    display: flex;
}

.right .right_top .xianshi {
    padding: 5px;
    border: 1px solid #d5d8db;
    color: #28334B;
    font-size: 12px;
    border-radius: 0px;
    margin-right: 10px;
}

.right .right_top .xianshi:hover {
    color: #1890ff;
    border: 1px solid #1890ff;
}


::v-deep .el-dialog__body .el-checkbox-group {
    display: flex;
}

::v-deep .el-dialog__body ul:nth-child(1) {
    padding: 0px;
}

::v-deep .el-dialog__body ul li {
    list-style: none;
    margin-bottom: 10px;
}

.dell {
    display: flex;
    align-items: center;
}

.dell ::v-deep .el-button {
    padding: 5px 10px;
    border-radius: 0px;

}

.dell .el-form {
    margin-left: 10px;
}

.dell .el-form .el-form-item {
    margin-bottom: 0px;
}


::v-deep .el-date-editor .el-input__icon:nth-child(1) {
    display: none;
}

.biaodan {
    margin: 10px 0;
}

.biaodan ::v-deep .el-table .el-table__header-wrapper .el-table__header .has-gutter tr th {
    background: #F4F7FB;
    color: #8DA2B5;
}

.biaotou1 {
    position: absolute;
    left: 66px;
    top: 4px;
}

.biaotou {
    position: absolute;
    left: 52px;
    top: 4px;
}

.block {
    display: flex;
    justify-content: end;
}

.block ::v-deep .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 8px;
}

.block ::v-deep .el-pagination__sizes {
    margin-right: 0;
}

.block ::v-deep .el-pagination__sizes .el-input {
    margin-right: 0;
}

.block ::v-deep .el-pager li,
.block ::v-deep .el-pagination button {
    border: 1px solid #ebebeb;
    background-color: #fff;
    font-weight: initial;
    margin: 0 3px;
}
</style>