import request from '../utils/request';

// 获取项目列表
const getProject = (data) => request.post('/project/project/list', data);
const addProject = (data) => request.post('/project/project/add', data);
const editProject = (data) => request.post('/project/project/edit', data);
const deleteProject = (data) => request.post('/project/project/destroy', data);
// 获取站点列表
const getSitelist = (data) => request.post('/site/setting/list', data);
const addSitelist = (data) => request.post('/site/setting/add', data);
const editSitelist = (data) => request.post('/site/setting/edit', data);
const deleteSitelist = (data) => request.post('/site/setting/destroy', data);
// 获取地区列表
const getRegion = (data) => request.post('/region/region/list', data);
const addRegion = (data) => request.post('/region/region/add', data);
const upRegion = (data) => request.post('/region/region/edit', data);
const deleteRegion = (data) => request.post('/region/region/destroy', data);
// 获取小组列表
const getGroup = (data) => request.post('/admin/group/list', data);
const addGroup = (data) => request.post('/admin/group/add', data);
const editGroup = (data) => request.post('/admin/group/edit', data);
const deleteGroup = (data) => request.post('/admin/group/delete', data);
// 获取管理员列表
const getAdmin = (data) => request.post('/admin/admin/list', data);
// 访客名单列表
const fklist = (data) => request.post('/visitor/user/list', data);
// 聊天列表
const ltlist = (data) => request.post('/record/chat/list', data);
// 获取黑名单列表
const getbaklst = (data) => request.post('/visitor/blacklist/ip_blacklist', data);
const addbaklst = (data) => request.post('/visitor/blacklist/add_ip_blacklist', data);
const editbaklst = (data) => request.post('visitor/blacklist/edit_ip_blacklist', data);
const delbaklst = (data) => request.post('/visitor/blacklist/destroy_ip_blacklist', data);
// 获取站点列表
const getsite = (data) => request.post('/site/setting/getSiteInfo', data);
//获取管理员列表
const getConservator = (data) => request.post('/admin/admin/list', data);
const addConservator = (data) => request.post('/admin/admin/add', data);
const editConservator = (data) => request.post('/admin/admin/edit', data);
const deleteConservator = (data) => request.post('/admin/admin/delete', data);
// 获取快捷回复标签列表
const getReply = (data) => request.post('/site/setting/getAllTags', data);
// 获取留言列表
const getLeave = (data) => request.post('/message/Leave_Message/list', data);
const delLeave = (data) => request.post('/message/Leave_Message/destroy', data);
// 渲染风格列表
const getStyle = (data) => request.post('/style/setting/list', data);
// 添加风格移动端
const addStyle = (data) => request.post('/style/setting/add', data);
// 添加风格pc端
const stylesave = (data) => request.post('/style/setting/add', data);
export {
  getProject,
  addProject,
  editProject,
  deleteProject,
  addStyle,
  getSitelist,
  addSitelist,
  editSitelist,
  deleteSitelist,
  getRegion,
  addRegion,
  upRegion,
  deleteRegion,
  getGroup,
  addGroup,
  editGroup,
  deleteGroup,
  getAdmin,
  fklist,
  ltlist,
  getbaklst,
  addbaklst,
  editbaklst,
  delbaklst,
  getsite,
  getConservator,
  addConservator,
  editConservator,
  deleteConservator,
  getReply,
  getLeave,
  delLeave,
  getStyle,
  stylesave
}